import { Icon } from "@components/Icons"
import { useStores } from "@hooks/use-stores"
import { Modal } from "antd"
import cx from "classnames"
import React, { useState } from "react"

import styles from "../style.module.less"

const Footer = (props) => {
  const {
    likes,
    liked,
    handleLikeButton,
    handleCommentsButton,
    handleShareButton,
    comments,
    totalComments,
    affiliatedId,
    postId,
  } = props

  const { post, authSite } = useStores()

  const { deleteSitePost, editSitePost } = post

  const { user } = authSite

  const handleDeletePost = async () => {
    Modal.confirm({
      title: "Você tem certeza que deseja deletar esse item?",
      onOk: async () => {
        await deleteSitePost(postId)
      },
      okType: "danger",
      okText: "Sim",
      cancelText: "Não",
    })
  }

  const handleEditPost = async (id, data) => {
    const edit = data.find((item) => item.id === id)
    edit.data = data

    const response = await editSitePost(edit.id, edit)
    const errorMessage = _get(response, "message", "")

    if (errorMessage) {
      const adaptedErrors = errorsAdapter(errorMessage)
      setErrors(adaptedErrors)
      return
    }
    handleClose()
  }

  const totalCommentsCount =
    comments &&
    comments.reduce(
      (acc, cur) => acc + 1 + cur.replys.reduce((replyAcc) => replyAcc + 1, 0),
      0
    )

  const HeartIcon = () =>
    liked ? (
      <img
        className=""
        src={require("@images/icons/heart-full-color.svg")}
        width="24px"
        height="24px"
        alt="Curtidas"
      />
    ) : (
      <img
        className=""
        src={require("@images/icons/heart-line.svg")}
        width="24px"
        height="24px"
        alt="Curtidas"
      />
    )

  return (
    <div
      className={cx(
        "ph16 flex flex-row items-center",
        styles.paddingMobile,
        styles.footer__post__item
      )}
    >
      <div className={cx("flex items-center", styles.likes__and__comments)}>
        <button
          className="input-reset bn pa0 pointer flex items-center"
          onClick={handleLikeButton}
        >
          <HeartIcon /> <span className="ml4 mr12 comet">{likes}</span>
        </button>

        <button
          className="input-reset bn pa0 pointer flex items-center"
          onClick={handleCommentsButton}
        >
          <img
            className="pointer"
            src={require("@images/icons/comment-line.svg")}
            width="24px"
            height="24px"
            alt="Comentários"
          />

          <span className="ml4 comet">
            {totalComments
              ? totalCommentsCount > 0
                ? totalCommentsCount
                : totalComments
              : totalCommentsCount}
          </span>
        </button>

        {affiliatedId === user.id && (
          <button
            onClick={handleDeletePost}
            style={{ backgroundColor: "#000" }}
            className="input-reset bg-red bn pa0 pointer"
          >
            <p>deletar post</p>
          </button>
        )}
      </div>

      {/* <div className="donate-area">
        <Icon.DollarSign color="#808191" size="20" /> Fazer doação
      </div> */}

      {props.share ? (
        <Icon name="Shared" className="f24" onClick={handleShareButton} />
      ) : null}
    </div>
  )
}

export default Footer
