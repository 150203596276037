import { EllipsisText } from "@components/EllipsisText"
import VideoWatermarkPlayer from "@components/VideoWatermarkPlayer"
import { useDimensions } from "@hooks/use-dimensions"
import { useResize } from "@hooks/use-resize"
import { useStores } from "@hooks/use-stores"
import { getWatermarkURL } from "@utils/path"
import { Image, Input, Menu, Modal } from "antd"
import {
  DownOutlined,
  UserOutlined,
  EditFilled,
  DeleteFilled,
  EyeFilled,
} from "@ant-design/icons"
import cx from "classnames"
import _map from "lodash/map"
import _get from "lodash/get"
import React, { useEffect, useRef, useState } from "react"
import { useMedia } from "react-use-media"

import PostComment from "../PostComments"
import PostModal from "../PostModal"

import PostModalVideo from "../postModalVideo"

import Footer from "./components/Footer"
import Header from "./components/Header"
import styles from "./style.module.less"
import { normalizePath } from "../../utils/path"
import { navigate } from "gatsby"
import ModelPost from "../modelPost"

const { TextArea } = Input

const getVideoDimensions = (screenWidth, textHeight) => {
  const videoHeight = textHeight === 0 ? 463 : 463 - textHeight

  let videoWidth = screenWidth

  if (videoWidth >= 768 && videoWidth < 1024) {
    videoWidth = videoWidth / 2 - 48
  }

  if (videoWidth >= 1024 && videoWidth < 1328) {
    videoWidth = (0.95 * videoWidth) / 3 - 48
  }

  if (videoWidth >= 1328) {
    videoWidth = 1328 / 3 - 64
  }

  return [videoWidth, videoHeight]
}

const PostCard = ({
  item,
  handleLikeUnlike,
  isProfile,
  nickname,
  setRefreshFeed,
}) => {
  const [visible, setVisible] = useState(false)
  const [edit, setEdit] = useState(false)
  const [editText, setEditText] = useState(item.postText)
  const [showComments, setShowComments] = useState(false)
  const [likes, setLikes] = useState(item.likes)
  const [liked, setLiked] = useState(item.liked)
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(false)

  const [videoModalPost, setVideoModalPost] = useState(false)

  const videoRef = useRef(null)
  const videoWrapperRef = useRef()

  const { feed, authSite, post } = useStores()
  const { user } = authSite
  const {
    getPost,
    postLike,
    postDislike,
    postComment,
    postReplyComment,
    deleteComment,
    deleteReply,
  } = feed
  const { deleteSitePost, editSitePost } = post

  const { getCloudinaryWatermarkLink } = post

  const isMobile = useMedia({
    maxWidth: 767,
  })
  const isDesktop = useMedia({ minWidth: 1025 })

  const [screenWidth] = useResize()

  const [textRef, { height: textHeight }, update, setUpdate] = useDimensions()
  const [imageWatermarkUrl, setImageWatermarkUrl] = useState(null)

  const srcImage = getWatermarkURL(item.urlImage, user)

  const getCloudinaryImageURL = async () => {
    const imageLink = await getCloudinaryWatermarkLink(srcImage)
    setImageWatermarkUrl(imageLink)
  }

  const handleDeletePost = async () => {
    setLoading(false)
    Modal.confirm({
      title: "Você tem certeza que deseja deletar esse item?",
      onOk: async () => {
        await deleteSitePost(item.id)
      },
      okType: "danger",
      okText: "Sim",
      cancelText: "Não",
    })
    setRefreshFeed(true)
    setLoading(true)
  }

  function handleButtonClick(e) {
    console.log("click left button", e)
  }

  const handleVideoModal = () => {
    setVideoModalPost(true)
  }

  // function handleMenuClick(e) {
  //   console.log("click", e)
  // }

  const handleClickPost = (event, id) => {
    const pathUri = normalizePath(`/post-perfil/${item.id}`)
    navigate(pathUri)
    event.stopPropagation()

    // console.log("aqui vai pra rota =>", pathUri)
  }

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<EditFilled />}>
        {item.affiliatedId === user.id && !edit && (
          <button
            onClick={() => setEdit(true)}
            style={{ backgroundColor: "#000", margin: 10 }}
            className="input-reset bg-red bn pa0 pointer"
          >
            <p>editar post</p>
          </button>
        )}
      </Menu.Item>
      <Menu.Item key="2" icon={<DeleteFilled />}>
        {item.affiliatedId === user.id && (
          <button
            onClick={handleDeletePost}
            style={{ backgroundColor: "#000" }}
            className="input-reset bg-red bn pa0 pointer"
          >
            <p>deletar post</p>
          </button>
        )}
      </Menu.Item>
      <Menu.Item key="3" icon={<EyeFilled />}>
        {item.affiliatedId === user.id && (
          <button
            onClick={handleClickPost}
            style={{ backgroundColor: "#000" }}
            className="input-reset bg-red bn pa0 pointer"
          >
            <p>Ver Post</p>
          </button>
        )}
      </Menu.Item>
    </Menu>
  )

  // useEffect(() => {
  //   getCloudinaryImageURL()
  // }, [item])

  // useEffect(() => {
  //   if (videoWrapperRef.current !== undefined) {
  //     const video = videoWrapperRef.current.children[0].children[0]
  //     video.style.objectFit = "cover"
  //   }
  // }, [])

  const handleShowModal = () => {
    if (screenWidth < 768) {
      return
    }

    setVisible(true)
  }

  const handleTextChange = async (event) => {
    setEditText(event.target.value)
  }

  const handleEditPost = async () => {
    setLoading(false)
    const newItem = {
      ...item,
    }

    newItem.postText = editText

    const response = await editSitePost(newItem.id, newItem)
    setEdit(false)
    const errorMessage = _get(response, "message", "")

    if (errorMessage) {
      const adaptedErrors = errorsAdapter(errorMessage)
      setErrors(adaptedErrors)
      return
    }
    setRefreshFeed(true)
    setLoading(true)
  }

  const handleVideoPlay = () => {
    if (videoWrapperRef.current != undefined) {
      const video = videoWrapperRef.current.children[0].children[0]
      video.style.objectFit = "contain"
    }
  }

  const handleLikeButton = async (isLiked) => {
    if (isLiked) {
      const { data } = await postDislike({
        postId: item.id,
        affiliatedId: item.affiliatedId,
      })

      if (data.success) {
        setLiked(false)
        setLikes(data.likeCnt)
      }
    } else {
      const { data } = await postLike({
        postId: item.id,
        affiliatedId: item.affiliatedId,
      })

      if (data.success) {
        setLiked(true)
        setLikes(data.likeCnt)
      }
    }
    if (handleLikeUnlike) handleLikeUnlike()
  }

  const onComment = async (values) => {
    const { textComment, commentId, user } = values

    if (!commentId) {
      const { data } = await postComment({
        postId: item.id,
        affiliatedId: item.affiliatedId,
        textComment,
      })

      setComments((prevState) => [
        {
          commentId: data.comment.id,
          textComment: data.comment.textComment,
          commentatorId: data.comment.commentatorId,
          commentatorName: user.name,
          commentatorPhoto: user.urlProfile,
          replys: [],
        },
        ...prevState,
      ])

      return
    }

    if (commentId) {
      const { data } = await postReplyComment({ commentId, textComment })

      setComments(
        _map(comments, (comment) => {
          if (comment.commentId === commentId) {
            return {
              ...comment,
              replys: [
                {
                  idReply: data.comment.id,
                  textComment: data.comment.textComment,
                  commentatorId: data.comment.commentatorId,
                  commentatorName: user.name,
                  commentatorPhoto: user.urlProfile,
                },
                ...comment.replys,
              ],
            }
          }

          return comment
        })
      )

      return
    }
  }

  const onDeleteComment = async ({ commentId }) => {
    const { data } = await deleteComment({ commentId })

    if (data.success) {
      setComments(comments.filter((comment) => comment.commentId !== commentId))
    }
  }

  const onDeleteReply = async ({ commentId, replyId }) => {
    const { data } = await deleteReply({ replyId })

    if (data.success) {
      setComments(
        _map(comments, (comment) => {
          if (comment.commentId === commentId) {
            return {
              ...comment,
              replys: comment.replys.filter(
                (reply) => reply.idReply !== replyId
              ),
            }
          }

          return comment
        })
      )
    }
  }

  const [videoWidth, videoHeight] = getVideoDimensions(screenWidth, textHeight)

  // PEGA A URL DO VIDEO DA AWS E CRIA A CAPA DO VIDEO COM EXTENSAO .JPG
  const videoCover = `${process.env.S3_IMAGES}/${item.urlVideo}`
  const videoCoverUrl =
    videoCover.substr(0, videoCover.lastIndexOf(".")) + ".jpg"

  return (
    <>
      {isDesktop ? (
        <>
          <div className={cx(styles.containerMediaAndFooter)}>
            {item.urlImage && (
              <Image
                style={{
                  backgroundImage: `url("${process.env.S3_IMAGES}/${item.urlImage}")`,
                  maxWidth: "280px",
                  minHeight: "360px",
                }}
                role="button"
                // loading="lazy"
                className={cx("pointer", styles.imageContainer)}
                preview={{ src: `${process.env.S3_IMAGES}/${item.urlImage}` }}
              />
            )}

            {item.urlVideo && (
              <div className="relative">
                <div ref={videoWrapperRef}>
                  <div
                    className="postVideoContainer"
                    style={{
                      backgroundImage: `url(${videoCoverUrl})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      width: "100%",
                      height: "360px",
                      marginBottom: "5px",
                    }}
                  >
                    <img
                      onClick={handleVideoModal}
                      alt="Nenhum favorito"
                      src={require("@images/icons/videoPlayer.svg")}
                    />

                    {/* <h1 >botão de play</h1> */}
                  </div>

                  {/* <video
                    styles={{
                      maxWidth: "280px",
                      minHeight: "422px",
                    }}
                    className={cx("pointer", styles.videoPlayer)}
                    width="100%"
                    poster={videoCoverUrl}
                    preload="none"
                    height="422px"
                    controls
                    playsInline
                    loop
                    controlsList="nodownload"
                  >
                    <source
                      src={`${process.env.S3_IMAGES}/${item.urlVideo}`}
                      type="video/mp4"
                    />
                  </video> */}
                </div>
              </div>
            )}
          </div>

          <Footer
            setRefreshFeed={setRefreshFeed}
            postId={item.id}
            affiliatedId={item.affiliatedId}
            likes={likes}
            liked={liked}
            comments={comments}
            totalComments={item.comments}
            handleLikeButton={() => handleLikeButton(liked)}
            handleCommentsButton={
              isMobile
                ? () => setShowComments(!showComments)
                : () => handleShowModal()
            }
          />
        </>
      ) : (
        <>
          {item.urlImage && (
            <Image
              style={{
                backgroundImage: `url("${process.env.S3_IMAGES}/${item.urlImage}")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              role="button"
              // loading="lazy"
              className={cx("pointer", styles.imageContainer)}
              preview={{ src: `${process.env.S3_IMAGES}/${item.urlImage}` }}
              height={422}
              width="100%"
            />
          )}

          {item.urlVideo && (
            <div className="relative">
              <div ref={videoWrapperRef}>
                <div
                  className="postVideoContainer"
                  style={{
                    backgroundImage: `url(${videoCoverUrl})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    width: "100%",
                    borderRadius: "24px",
                    marginBottom: "5px",
                    height: "422px",
                  }}
                >
                  <img
                    onClick={handleVideoModal}
                    alt="Nenhum favorito"
                    src={require("@images/icons/videoPlayer.svg")}
                  />

                  {/* <h1 >botão de play</h1> */}
                </div>
                {/* <video
                  width="100%"
                  height="422"
                  poster={videoCoverUrl}
                  preload="none"
                  className={cx("pointer", styles.videoPlayer)}
                  controls
                  playsInline
                  loop
                  controlsList="nodownload"
                >
                  <source
                    src={`${process.env.S3_IMAGES}/${item.urlVideo}`}
                    type="video/mp4"
                  />
                </video> */}
              </div>
            </div>
          )}

          <Footer
            setRefreshFeed={setRefreshFeed}
            postId={item.id}
            affiliatedId={item.affiliatedId}
            likes={likes}
            liked={liked}
            comments={comments}
            totalComments={item.comments}
            handleLikeButton={() => handleLikeButton(liked)}
            handleCommentsButton={
              isMobile
                ? () => setShowComments(!showComments)
                : () => handleShowModal()
            }
          />
        </>
      )}

      {showComments && isMobile && (
        <PostComment
          handleCloseModal={() => setShowComments(false)}
          getPost={getPost}
          item={item}
          comments={comments}
          setComments={setComments}
          onComment={onComment}
          onDeleteComment={onDeleteComment}
          onDeleteReply={onDeleteReply}
        />
      )}

      {videoModalPost && (
        <PostModalVideo
          visible={videoModalPost}
          handleCloseModal={() => setVideoModalPost(false)}
          getPost={getPost}
          item={item}
          likes={likes}
          liked={liked}
          handleLikeButton={() => handleLikeButton(liked)}
          setComments={setComments}
          onComment={onComment}
          onDeleteComment={onDeleteComment}
          onDeleteReply={onDeleteReply}
        />
      )}

      {visible && (
        <PostModal
          visible={visible}
          handleCloseModal={() => setVisible(false)}
          getPost={getPost}
          item={item}
          likes={likes}
          liked={liked}
          handleLikeButton={() => handleLikeButton(liked)}
          comments={comments}
          setComments={setComments}
          onComment={onComment}
          onDeleteComment={onDeleteComment}
          onDeleteReply={onDeleteReply}
        />
      )}
    </>
  )
}

export default PostCard
